.table-container {
    margin-top: 10px;
    width: 100%;
    margin-bottom: 20px;
    overflow-x: auto;
  }
  
  .table-header {
    display: flex;
    flex-direction: column;
    font-weight: bold;
  }
  
  .table-body {
    display: flex;
    flex-direction: column;
  }
  
  .table-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #070000;
  }
  
  .table-cell {
    flex: 1 1 120px;
    padding: 10px;
    text-align: center;
    min-width: 120px;
  }
  
  .table-cell[data-label="Actions"] {
    flex: 1 1 120px;
  }
  
  .table-cell[data-label="Scores"] {
    white-space: pre-line;
  }
  
  /* Media Query for responsive mode */
  @media (max-width: 768px) {
    .table-header {
      display: none; /* Hide table headers on small screens */
      border-top: 2px solid #070000
    }
  
    .table-body {
      display: flex;
      flex-wrap: wrap; /* Allow rows to wrap next to each other */
    }
  
    .table-row {
      flex-direction: row;
      flex-wrap: wrap; /* Allow cells within a row to wrap */
      align-items: flex-start;
      width: 100%;
      border-bottom: 2px solid #070000;
      border-top: 2px solid #070000
    }
  
    .table-cell {
      padding: 5px 0;
      min-width: 0;
      flex: 1 1 48%; /* Allow cells to take up half the width */
      text-align: left;
    }
  
    .table-cell[data-label="Scores"] {
      flex: 1 1 100%; /* Make the Scores cell take up the full width */
      margin-top: 10px; /* Add spacing before Scores row */
    }
  
    .table-cell::before {
      content: attr(data-label);
      font-weight: bold;
      display: block;
      margin-bottom: 5px;
    }
  }
  .ReactModal__Content table {
    width: 100%; /* Makes sure table takes up full width */
    border-collapse: collapse; /* Ensures clean borders */
    background: white; /* Maintains a clear background */
  }
  
  .ReactModal__Content th,
  .ReactModal__Content td {
    border: 1px solid #ccc; /* Adds visible borders around table cells */
    padding: 8px;
    text-align: center; /* Centers text for readability */
  }
  
  .ReactModal__Content th {
    background-color: #f4f4f4; /* Light background for headers */
    font-weight: bold;
  }
  
  .ReactModal__Content tr:nth-child(even) {
    background-color: #f9f9f9; /* Alternating row colors */
  }
  
  .ReactModal__Overlay {
    opacity: 1 !important;
    visibility: visible !important;
  }
  
  .ReactModal__Content {
    opacity: 1 !important;
    visibility: visible !important;
    display: block !important;
  }

  .ReactModal__Content p {
    margin: 2px 0; /* Reduces vertical spacing between paragraphs */
    line-height: 1.2; /* Reduces the space between lines */
    font-size: 14px; /* Optionally reduce font size for compactness */
  }

  /* Container for labels and values */
.ReactModal__Content .details-container {
  display: grid;
  grid-template-columns: auto 1fr; /* Label on the left, value on the right */
  gap: 6px 16px; /* Less vertical gap, more horizontal spacing */
  align-items: center;
  width: 100%;
  max-width: 600px; /* Adjust max width for a clean look */
  margin-bottom: 16px; /* Adds space below the section */
}

/* Styling for labels */
.ReactModal__Content .label {
  font-weight: bold;
  text-align: right;
  color: #333; /* Darker text for better readability */
  white-space: nowrap; /* Prevents wrapping */
}

/* Styling for values */
.ReactModal__Content .value {
  text-align: left;
  color: #555; /* Slightly lighter than label */
}
