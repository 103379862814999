/* Navbar.css */
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #333;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .navbar-toggle {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  .navbar-links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .navbar-links li {
    margin: 0 10px;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .navbar-toggle {
      display: block;
    }
  
    .navbar-links {
      display: none;
      flex-direction: column;
      width: 100%;
      text-align: center;
    }
  
    .navbar-links.open {
      display: flex;
    }
  
    .navbar-links li {
      margin: 10px 0;
    }
  }
  