

.App {
  text-align: center;
}

.App-header {
  background-color: #010202;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* styles.css or App.css */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: 2px solid black;
  border-bottom: 1px solid #000;
}

th, td {
  flex: 1;
  padding: 10px 15px;
  text-align: left;
  border-bottom: 1px solid #000;
}

th {
  background-color: #f2f2f2;
  border-bottom: 1px solid #000;
}

tr{
  background-color: #f2f2f2;
  border: solid;
  border-width: 1px 0;
}

#banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 0;
  background-color: #f8f9fa; /* Optional: add a background color to the banner */
}

#banner img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  #banner {
    padding: 5px 0;
  }

  #banner img {
    width: 90%;
    height: auto;
  }
}

.quill-wrapper {
  resize: vertical;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.quill-wrapper .ql-container {
  min-height: 150px;
}

.quill-wrapper:focus-within {
  border-color: #66afe9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 175, 233, 0.6);
}

.is-invalid {
  border-color: red !important;
}

.invalid-feedback {
  color: red;
  font-size: 0.85rem;
  margin-top: 4px;
}
